import axios from 'axios';
import config from '../config';

let configUrl = config.apiUrl;

// Функция для обновления настроек


// Функция для обновления настроек
export const updateSettings = async (
  videoLink,
  chinaAddress,
  whatsappNumber,
  aboutUsText,
  prohibitedItemsText
) => {
  const data = {
    videoLink,
    chinaAddress,
    whatsappNumber,
    aboutUsText,
    prohibitedItemsText
  };

  try {
    // Отправляем POST-запрос на сервер в формате JSON
    const response = await axios.post(`${configUrl}/api/settings/updateSettings`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data; // Если запрос выполнен успешно, возвращаем данные ответа
  } catch (error) {
    if (error.response) {
      console.error('Ошибка при обновлении настроек:', error.response.data);
      throw error.response.data; // Возвращаем данные ошибки
    } else {
      console.error('Ошибка при обновлении настроек:', error.message);
      throw new Error('Ошибка при обновлении настроек'); // Обработка других ошибок
    }
  }
};

export const getSettings = async () => {
    try {
      // Отправляем GET запрос на сервер для получения данных о всех филиалах
      const response = await axios.get(`${configUrl}/api/settings/getSettings`);
      
      // Если запрос выполнен успешно, возвращаем данные ответа
      return response.data;
    } catch (error) {
      // Если произошла ошибка, выводим её в консоль и возвращаем null
      console.error('Ошибка при получении данных о филиалах:', error);
      return null;
    }
  };


// Функция для загрузки контракта
export const uploadContract = async (file) => {
  const formData = new FormData();
  formData.append('contract', file); // Здесь имя 'contract' должно совпадать с тем, что используется на сервере

  try {
      const response = await axios.post(`${configUrl}/api/upload/uploadContract`, formData, {
          headers: {
              'Content-Type': 'multipart/form-data',
          }
      });
      return response.data; // Возвращаем ответ от сервера
  } catch (error) {
      // Обработка ошибок
      if (error.response) {
          console.error('Ошибка при загрузке контракта:', error.response.data);
          throw error.response.data; // Возвращаем данные ошибки
      } else {
          console.error('Ошибка при загрузке контракта:', error.message);
          throw new Error('Ошибка при загрузке контракта');
      }
  }
};



export const updatePrice = async (price,currency) => {
try {
  // Отправляем POST запрос на сервер для добавления нового филиала
  const response = await axios.post(`${configUrl}/api/settings/updatePrice`, {price,currency});

  // Если запрос выполнен успешно, возвращаем данные ответа
  return response.data;
  
} catch (error) {
  if (error.response && error.response.status === 400) {
      const { message, errors } = error.response.data;
      console.log('Validation errors:', errors);
      alert(message);
    } else {
          // Если есть ошибка валидации, отображаем сообщение об ошибке
      if (error.response && error.response.status === 400) {
        const { message, errors } = error.response.data;
        console.log('Validation errors:', errors);
        alert(message);
      } else {
        // Если произошла другая ошибка, выводим сообщение об ошибке в консоль
        console.error('Error:', error.message);
      }
    }
}
};

export const getPrice = async () => {
  try {
    // Отправляем GET запрос на сервер для получения данных о всех филиалах
    const response = await axios.get(`${configUrl}/api/settings/getPrice`);
    
    // Если запрос выполнен успешно, возвращаем данные ответа
    return response.data;
  } catch (error) {
    // Если произошла ошибка, выводим её в консоль и возвращаем null
    console.error('Ошибка при получении данных о филиалах:', error);
    return null;
  }
};



// Функция для обновления глобального процента бонуса
export const updateGlobalBonusPercentage = async (percent) => {
    try {
        const response = await axios.put(`${configUrl}/api/settings/globalBonus`, { globalReferralBonusPercentage: percent });
        return response.data;
    } catch (error) {
        throw new Error('Ошибка при обновлении глобального процента бонуса');
    }
};







export const updateContacts = async (phone, whatsappPhone, whatsappLink, instagram, telegramId, telegramLink) => {
  try {
    // Отправляем POST запрос на сервер для добавления нового филиала
    const response = await axios.post(`${configUrl}/api/settings/updateContacts`, {phone, whatsappPhone, whatsappLink, instagram, telegramId, telegramLink});
  
    // Если запрос выполнен успешно, возвращаем данные ответа
    return response.data;
    
  } catch (error) {
    if (error.response && error.response.status === 400) {
        const { message, errors } = error.response.data;
        console.log('Validation errors:', errors);
        alert(message);
      } else {
            // Если есть ошибка валидации, отображаем сообщение об ошибке
        if (error.response && error.response.status === 400) {
          const { message, errors } = error.response.data;
          console.log('Validation errors:', errors);
          alert(message);
        } else {
          // Если произошла другая ошибка, выводим сообщение об ошибке в консоль
          console.error('Error:', error.message);
        }
      }
  }
};

export const getContacts = async () => {
  try {
    // Отправляем GET запрос на сервер для получения данных о всех филиалах
    const response = await axios.get(`${configUrl}/api/settings/getContacts`);
    
    // Если запрос выполнен успешно, возвращаем данные ответа
    return response.data;
  } catch (error) {
    // Если произошла ошибка, выводим её в консоль и возвращаем null
    console.error('Ошибка при получении данных о филиалах:', error);
    return null;
  }
};
